import { Box, Text } from '@chakra-ui/react'

export interface CopyrightFooterProps {
  copyrightText?: string
}

export const DDCFooterCopywrite = ({ copyrightText }: CopyrightFooterProps) => {
  return (
    <Box backgroundColor={'var(--chakra-colors-shading-100)'}>
      <Box
        width={{ base: '100%', '2xl': 'container.2xl' }}
        margin={'auto'}
        background="shading.100"
        padding={{
          base: 'var(--chakra-space-7,28px) 0px 0px 0px',
          '2xl': ' var(--chakra-space-10) var(--chakra-space-16,64px) 0 var(--chakra-space-16,64px)',
          md: 'var(--chakra-space-10) var(--chakra-space-4) 0 var(--chakra-space-4)',
        }}
        height={'auto !important'}
      >
        <Text
          borderTopWidth="1px"
          borderTopColor={'primary.200'}
          fontSize="sm"
          color="text.primary"
          padding="var(--chakra-space-4)"
        >
          {copyrightText}
        </Text>
      </Box>
    </Box>
  )
}
